import { put, select, take, all } from 'redux-saga/effects'

import _ from 'lodash'
import { getContentId, getOwnerId } from '@tabeeb/modules/shared/content/selectors'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { convertServicePageToPluginPage } from '@tabeeb/services/dataConverter'
import * as galleryActions from '../actions'

export default function* loadGallery({ selectedPageId, preserveSelectedPage = false }) {
  const contentId = yield select(getContentId)
  const contentOwnerId = yield select(getOwnerId)
  const currentUserId = yield select(getCurrentUserId)

  const pageToPluginPageParams = {
    contentId,
    contentOwnerId,
    currentUserId,
  }

  yield put(galleryActions.getSessionFoldersRequest(contentId))
  const foldersResult = yield take([galleryActions.getSessionFoldersSuccess])
  const folders = foldersResult.response.data

  yield put(galleryActions.getPagesByContentIdRequest(contentId))
  const pagesResult = yield take([galleryActions.getPagesByContentIdSuccess])
  const contentPages = pagesResult.response.data

  const pages = contentPages.map((page) => convertServicePageToPluginPage({ ...pageToPluginPageParams, page }))

  const actions = [put(galleryActions.setGalleryItems(pages)), put(galleryActions.setFoldersList(folders))]

  if (pages.length > 0 && !preserveSelectedPage) {
    if (!selectedPageId) {
      let selectedPage = null
      const firstPageInMainList = _.minBy(
        pages.filter((page) => page.folderId === null),
        (page) => page.order
      )

      if (firstPageInMainList) selectedPage = firstPageInMainList
      else selectedPage = pages[0]

      selectedPageId = selectedPage.id
    }

    actions.push(put(galleryActions.onSelectGalleryItem({ pageId: selectedPageId, updateWeb: true })))
  }

  yield all(actions)
}
